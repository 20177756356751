export interface Project {
    mainColor: string,
    defaultScene: string;
    scenes: Scene[];
    typeColors: number[][];
    layouts: Layouts;
    price: boolean;
    tooltip : {
        image_icons : boolean
    }
}

export interface Layouts {
    [key: string]: Layout;
}

export interface Layout {
    leftMenu?: LeftMenu[];
    image?: string
    [key: string]: any;
}

export interface LeftMenu {
    text: string;
    action: string;
    target: string;
    icon?: string;
}

export interface Scene {
    id: string;
    orbit?: string;
    layout: string;
    [key: string]: any;
}

const bot_menu_plan = [
    {
        text: "Plan",
        action: "setLayout",
        target: "poi_plan"
    },
]

const bot_menu_pa = [
    {
        text: "Plan",
        action: "setLayout",
        target: "poi_pa_plan"
    },
    {
        text: "Plan 3D",
        action: "setLayout",
        target: "poi_pa_axo"
    },
]

const bot_menu_opaa = [
    {
        text: "Orbital",
        action: "setLayout",
        target: "poi_opaa_orbit"
    },
    {
        text: "Plan",
        action: "setLayout",
        target: "poi_opaa_plan"
    },
    {
        text: "Plan 3D",
        action: "setLayout",
        target: "poi_opaa_axo1"
    },
    {
        text: "Plan 3D",
        action: "setLayout",
        target: "poi_opaa_axo2"
    },
]

const leftMenuHouse = [
    {
        text: "Toit",
        action: "setOrbit",
        target: "%orbit%_2"
    },
    {
        text: "R+1",
        action: "setOrbit",
        target: "%orbit%_1"
    },
    {
        text: "RDC",
        action: "setOrbit",
        target: "%orbit%_0"
    },
]

export const project: Project = {
    mainColor: "948276",
    defaultScene: "global",
    price: true,
    tooltip : {
        image_icons : false
    },
    typeColors: [
        [255, 186, 0, 255],
        [9, 128, 0, 255],
        [0, 21, 181, 255],
        [175, 0, 181, 255],
        [141, 0, 0, 255],
        [141, 65, 99, 255],
    ],

    // Same as poi
    scenes: [
        {
            id: "global",
            orbit: "global",
            layout: "base"
        },
        {
            id: "place",
            orbit: "place",
            layout: "base_back"
        },
        {
            id: "T5A_0",
            orbit: "T5A_0",
            layout: "base"
        },
        {
            id: "T5A_1",
            orbit: "T5A_1",
            layout: "base"
        },
        {
            id: "T5A_1",
            orbit: "T5A_1",
            layout: "base"
        },
        {
            id: "T5B_0",
            orbit: "T5B_0",
            layout: "base"
        },
        {
            id: "T5B_1",
            orbit: "T5B_1",
            layout: "base"
        },
        {
            id: "T6A_0",
            orbit: "T6A_0",
            layout: "base"
        },
        {
            id: "T6A_1",
            orbit: "T6A_1",
            layout: "base"
        },
        {
            id: "T6A_2",
            orbit: "T6A_2",
            layout: "base"
        },
        {
            id: "T6B_0",
            orbit: "T6B_0",
            layout: "base"
        },
        {
            id: "T6B_1",
            orbit: "T6B_1",
            layout: "base"
        },
        {
            id: "T6B_2",
            orbit: "T6B_2",
            layout: "base"
        },
        {
            id: "T6C_0",
            orbit: "T6C_0",
            layout: "base"
        },
        {
            id: "T6C_1",
            orbit: "T6C_1",
            layout: "base"
        },
        {
            id: "T6C_2",
            orbit: "T6C_2",
            layout: "base"
        },
        {
            id: "search",
            layout: "search"
        },
        {
            id: "favorites",
            layout: "favorites"
        }
    ],
    layouts: {

        poi_virtual: {
            tour: "%tour%",
            back: "layout",
        },


        poi_opaa_orbit: {
            orbit: "%orbit%_2",
            back: "scene",
            infoCard: "%id%",
            botMenu: bot_menu_opaa,
            leftMenu: leftMenuHouse,
            compas: true,
        },

        poi_opaa_plan: {
            image: "%plan%",
            back: "scene",
            infoCard: "%id%",
            botMenu: bot_menu_opaa,
        },

        poi_opaa_axo1: {
            image: "%axo%",
            back: "scene",
            infoCard: "%id%",
            botMenu: bot_menu_opaa,
        },

        poi_opaa_axo2: {
            image: "%axo2%",
            back: "scene",
            infoCard: "%id%",
            botMenu: bot_menu_opaa,
        },

        poi_pa_plan: {
            image: "%plan%",
            back: "scene",
            infoCard: "%id%",
            botMenu: bot_menu_pa,
        },
        poi_pa_axo: {
            image: "%axo%",
            back: "scene",
            infoCard: "%id%",
            botMenu: bot_menu_pa,
        },

        base: {
            orbit: "%orbit%",
            compas: true,
            searchButton: true
        },

        base_back: {
            orbit: "%orbit%",
            compas: true,
            searchButton: true,
            back: "scene",
        },


        search: {
            back: "scene",
            backColor: "FFFFFF",
            search: true,
        },

        favorites: {
            back: "scene",
            favorites: true,
        }

    }
}
