import React, { useEffect } from 'react';
import hive from "./index"
import { useState } from "@hookstate/core"
import LotCard from './LotCard';

function FavoriteTarget({ lotState }: any) {
  // width: totalWidth.get() * 0.49,
  // height: height.get() * 0.65,
  const { height, totalWidth, mainColor } = useState(hive.state)

  const lot = useState(lotState)
  const targetLot = Object.values(hive.lots).find((l: any) => l.id === lot.get()) as any

  return <div>
    {!targetLot && <>
      <div style={{
        width: "100%",
        height: height.get() * 0.65,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}>
        <div style={{
          display: "flex",
          justifyContent: "center",
          width: totalWidth.get() * 0.49,

        }}>
          <div style={{
            width: height.get() * 0.45,
            height: height.get() * 0.22,
            textAlign: "center",
            color: `#${mainColor.get()}`
          }}>
            <div style={{
              fontSize: height.get() * 0.03,
              fontFamily: "MuktaBold",
            }}

            >COMPARER</div>
            <div style={{
              fontSize: height.get() * 0.2 + "px",
              margin: -height.get() * 0.1 + "px",
            }}>+</div>
            <div
              style={{
                fontSize: height.get() * 0.02 + "px",
              }}
            >Glissez déposer vos favoris pour les comparer</div>
          </div>
        </div>

      </div>
    </>}

    {targetLot &&
      <div style={{
        width: "100%",
        height: height.get() * 0.65,
        backgroundImage: `url(${targetLot.plan.indexOf(".pdf") === -1 ? targetLot.plan : targetLot.thumb})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        backgroundPosition: "center",
      }}>
      </div>
    }
  </div>
}

function Favorites() {
  const { height, totalWidth, mainColor, favoriteList, favoriteLeft, favoriteRight } = useState(hive.state)

  const overDrop = useState("")
  const lotDrop = useState("")

  const getLotfromId = (id: string) => {
    const res = Object.values(hive.lots).find((lot: any) => lot.id === id)
    return res
  }

  const touchStart = (fav: string) => {
    (document.querySelector("#grabTooltip") as HTMLElement).style.visibility = `initial`;
    overDrop.set("")
    lotDrop.set(fav)
  }

  const touchMove = (x: number, y: number) => {
    if (x !== 0 && y !== 0) {
      (document.querySelector("#grabTooltip") as HTMLElement).style.top = `${y}px`;
      (document.querySelector("#grabTooltip") as HTMLElement).style.left = `${x}px`;
      const el = (document.elementFromPoint(x, y) as HTMLElement).getAttribute("id");
      (document.querySelector("#grabTooltip") as HTMLElement).style.visibility = (el === "drop_0" || el === "drop_1") ? "initial" : "hidden";

      if (el !== null) {
        if (overDrop.get() !== el) {
          overDrop.set(el)
        }
      }
    }
  }

  const touchEnd = () => {
    if (overDrop.get()) {
      if (overDrop.get() === "drop_0") {
        favoriteLeft.set(lotDrop.get())
      }
      if (overDrop.get() === "drop_1") {
        favoriteRight.set(lotDrop.get())
      }
    }
    lotDrop.set("")
    overDrop.set("");
    (document.querySelector("#grabTooltip") as HTMLElement).style.visibility = `hidden`;
  }

  useEffect(() => {
    const callBack = (e: any) => {
      touchMove(e.pageX, e.pageY)
      // console.log(e.pageX)
    }

    const d = document.addEventListener("dragover", callBack, true)
    return () => {
      document.removeEventListener("dragover", callBack, true)
    }
  }, [])

  return <div style={{
    position: "absolute",
    height: height.get(),
    background: "#dadada",
    width: totalWidth.get(),
    zIndex: 1,
  }}>
    <div
      id="grabTooltip"
      style={{
        position: "fixed",
        width: height.get() * 0.1,
        height: height.get() * 0.1,
        backgroundImage: "url(/gui/drag.png)",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        zIndex: 5,
        visibility: "hidden",
        pointerEvents: "none",
      }}>

    </div>

    {/* return space */}
    <div style={{
      height: height.get() * 0.07,
    }}>

    </div>

    {/* comparator */}

    <div style={{
      height: height.get() * 0.70,
      display: "flex",
      justifyContent: "space-evenly",
    }}>
      {[0, 1].map(e =>
        <div key={e}
          style={{
            width: totalWidth.get() * 0.49,
            height: height.get() * 0.65,
            border: overDrop.get() === `drop_${e}` ? `2px #${mainColor.get()} solid` : `2px #${mainColor.get()} dashed`,
            backgroundColor: overDrop.get() === `drop_${e}` ? `#${mainColor.get()}66` : "inherit"
          }}
        >
          <div
            id={`drop_${e}`}
            style={{
              position: "absolute",
              width: totalWidth.get() * 0.49,
              height: height.get() * 0.65,
            }}>
          </div>
          <FavoriteTarget lotState={e == 0 ? favoriteLeft : favoriteRight}></FavoriteTarget>
        </div>
      )}

    </div>

    {/* list */}
    <div style={{
      height: height.get() * 0.225,
      width: "100vw",
      display: "flex",
      // border : "1px solid blue"
      overflowX: "scroll",
    }}>
      {favoriteList.map((fav, i) =>
        <div
          draggable="true"
          onDragStart={(e) => {
            e.dataTransfer.setData('Text', "");
            touchStart(fav.get())
          }}

          // onDrag={(e) => {
          //   touchMove(e.pageX, e.pageY)
          // }}

          onDragEnd={(e) => {
            touchEnd()
          }}

          onTouchStart={() => {
            touchStart(fav.get())
          }}

          onTouchMove={(e) => {
            const x = e.targetTouches[0].clientX
            const y = e.targetTouches[0].clientY
            touchMove(x, y)
          }}

          onTouchEnd={(e) => {
            touchEnd()
          }}
          key={fav.get()}
          style={{
            cursor: "grab",
            width: totalWidth.get() * 0.25,
            minWidth: totalWidth.get() * 0.25,
            height: height.get() * 0.20,
            // background: "red",
            marginLeft: height.get() * 0.01,
          }}
        >
          <LotCard width={0.25} height={0.22} lot={getLotfromId(fav.get())}></LotCard>
        </div>
      )}
    </div>
  </div>
}


export default Favorites