import React, { useEffect } from 'react';
import hive from "./index"
import { useState } from "@hookstate/core"
import LotCard from './LotCard';
import HiveSlider from './HiveSlider';
import { project } from "./project"

function Search() {
    const { height, mainColor, searchResult, totalWidth } = useState(hive.state)
    const searchFilter = useState(hive.searchFilter)

    return <div style={{
        position: "absolute",
        height: height.get() + "px",
        width: "100vw",
        background: `#${mainColor.get()}BB`,
        zIndex: 1,
        color: "white",
    }}>
        <div style={{
            height: height.get() * 0.5 + "px"
        }}>
            <div style={{
                height: height.get() * 0.05,
            }}>
            </div>

            <div style={{
                width: totalWidth.get() * 0.5 + "px",
                margin: "auto",
            }}>
                <div style={{
                    // height: height.get() * 0.1,
                    // lineHeight: height.get() * 0.1 + "px",
                    fontSize: height.get() * 0.028 + "px",
                    display: "flex",
                }}>
                    <div style={{
                        height: height.get() * 0.05,
                        lineHeight: height.get() * 0.05 + "px",
                        width: totalWidth.get() * 0.1,

                    }}>
                        Typologies
                    </div>
                    {searchFilter.typologies.map((e, i) =>
                        <div key={i}
                            onClick={() => {
                                e.active.set((v) => !v)
                                hive.applyFilter()
                            }}
                            style={{
                                userSelect: "none",
                                cursor: "pointer",
                                width: height.get() * 0.05,
                                height: height.get() * 0.05,
                                borderRadius: height.get() * 0.007,
                                textAlign: "center",
                                lineHeight: height.get() * 0.05 + "px",
                                // background: e.active.get() ? `#${mainColor.get()}` : "white",
                                background: `rgb(${project.typeColors[i][0]},${project.typeColors[i][1]},${project.typeColors[i][2]})`,
                                color: "white",
                                marginLeft: height.get() * 0.01,
                                opacity: e.active.get() ? "1" : ( searchFilter.typologies.find(f => f.active.get())  ? "0.4" : "1")  ,
                            }}
                        >
                            {e.text.get()}
                        </div>
                    )}
                </div>
                <div style={{
                    height: height.get() * 0.05,
                }}>
                </div>

                {/* PRICE */}
                {hive.priceSlider.domain[1].get() > 5000 && <>
                    <div style={{
                        display: "flex",
                    }}>
                        <div style={{
                            height: height.get() * 0.05,
                            lineHeight: height.get() * 0.05 + "px",
                            marginTop: height.get() * 0.005,
                            width: totalWidth.get() * 0.1,
                            fontSize: height.get() * 0.028 + "px",
                        }}>
                            Prix
            </div>
                        <div style={{
                            width: totalWidth.get() * 0.4 + "px",
                            marginLeft: totalWidth.get() * 0.02,
                        }}>
                            <HiveSlider sliderState={hive.priceSlider}></HiveSlider>
                        </div>
                    </div>

                </>}



                <div style={{
                    height: height.get() * 0.05,
                }}>
                </div>
                <div style={{
                    display: "flex",
                }}>
                    <div style={{
                        height: height.get() * 0.05,
                        lineHeight: height.get() * 0.05 + "px",
                        marginTop: height.get() * 0.005,
                        fontSize: height.get() * 0.028 + "px",
                        width: totalWidth.get() * 0.1,
                    }}>
                        Surface
            </div>
                    <div style={{
                        width: totalWidth.get() * 0.4 + "px",
                        marginLeft: totalWidth.get() * 0.02,
                    }}>
                        <HiveSlider sliderState={hive.surfaceSlider}></HiveSlider>
                    </div>
                </div>
            </div>

        </div>
        <div style={{
            height: height.get() * 0.5 + "px",
            background: `#${mainColor.get()}`,
        }}>
            <div style={{
                textAlign: "center",
                color: "white",
                fontSize: height.get() * 0.02 + "px",
                height: height.get() * 0.15,
                lineHeight: height.get() * 0.15 + "px",
            }}>
                RESULTAT DE RECHERCHE
        </div>
            <div style={{
                display: "flex",
                width: "100vw",
                overflowX: "scroll",
                height: height.get() * 0.35,
            }}>
                {searchResult.map((lot, i) =>
                    <div key={i} style={{
                        marginLeft: totalWidth.get() * 0.01 + "px",
                        marginRight: totalWidth.get() * 0.01 + "px"
                    }}>
                        <LotCard lot={lot} width={0.3} height={0.25}></LotCard>
                    </div>)}
            </div>
        </div>

    </div >
}


export default Search